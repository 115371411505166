<template>
  <div>
    <Spinner v-if="fetching" />
    <div class="footer-margin" v-else>
      <section>
        <div class="container-fluid plr-80">
          <div class="property-name-wrapper row">
            <div class="col-md-6 hotel-rating">
              <p>{{ property.name }}</p>
              <h4 class="d-flex align-items-center">
                <span> {{ property.name }}</span>
                <StarRating v-if="ratingValue > 0" :value="ratingValue" />
              </h4>
              <div class="access d-flex">
                <li v-if="propertyInfo.addresses">
                  <a
                    :href="`https://www.google.com/maps?q=${propertyInfo.geoCode?.latitude},${propertyInfo.geoCode?.longitude}`"
                    target="_blank"
                    >{{ propertyInfo.addresses[0]?.addressLine }}</a
                  >
                </li>
                <li>
                  <a
                    :href="`https://www.google.com/maps?q=${propertyInfo.geoCode?.latitude},${propertyInfo.geoCode?.longitude}`"
                    target="_blank"
                    >{{ $t("listing.showOnMap") }}</a
                  >
                </li>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div class="container-fluid plr-80">
          <div class="property-pics-outer row position-relative">
            <div class="col-6 single-image">
              <div class="pics-img-outer" v-if="propertyImages.length">
                <img :src="propertyImages[0].url" alt="" />
              </div>
              <div class="pics-img-outer" v-else>
                <img src="/images/property-default-image.jpg" alt="" />
              </div>
            </div>
            <div class="col-6">
              <div class="row images-4">
                <div
                  v-for="(item, idx) in propertyImages.slice(1, 5)"
                  :key="item.id"
                  class="col-6"
                >
                  <div class="pics-img-outer position-relative">
                    <img :src="item.url" alt="" />
                    <button
                      v-if="idx === 3 && !viewAllImages"
                      class="btn btn-light total-images px-3 py-1 small position-absolute rounded shadow-lg"
                      style="bottom: 4px; right: 4px"
                      @click="viewAllImages = true"
                    >
                      View {{ propertyImages.length - 5 }} more photos
                    </button>
                  </div>
                </div>
                <template v-if="!propertyImages.length">
                  <div
                    v-for="i in Math.max(0, 3 - propertyImages.length)"
                    :key="i"
                    class="col-6"
                  >
                    <div class="pics-img-outer">
                      <img src="/images/property-default-image.jpg" alt="" />
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="col-12" v-if="viewAllImages">
              <div class="row images-4">
                <div
                  v-for="(item, idx) in propertyImages.slice(5)"
                  :key="item.id"
                  class="col-lg-3"
                >
                  <div class="pics-img-outer position-relative">
                    <img :src="item.url" alt="" />
                    <button
                      v-if="idx === propertyImages.length - 6"
                      class="btn btn-light total-images px-3 py-1 small position-absolute rounded shadow-lg"
                      style="bottom: 4px; right: 4px"
                      @click="viewAllImages = false"
                    >
                      View less photos
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <BookingCheckout
          :type="property.distributorType"
          :cart="cart"
          :extras="[]"
          v-if="cart.length"
          @proceed="proceedToReservation"
          @clear="cart = []"
        />
      </section>

      <section>
        <div class="container-fluid plr-80">
          <div class="property-pics-mob">
            <b-carousel
              id="carousel-fade"
              style="text-shadow: 0px 0px 2px #000"
              fade
              indicators
              img-width="1024"
              img-height="480"
            >
              <template v-if="propertyImages.length">
                <b-carousel-slide
                  v-for="item of propertyImages"
                  :key="item.id"
                  :img-src="item.url"
                ></b-carousel-slide>
              </template>
              <b-carousel-slide
                v-else
                img-src="/images/property-default-image.jpg"
              ></b-carousel-slide>
            </b-carousel>
          </div>
        </div>
      </section>

      <section>
        <div class="container-fluid plr-80">
          <div class="property-desc">
            <div class="row align-items-center">
              <div class="col-md-8">
                <div v-html="description"></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        v-if="property.distributorType === 'A'"
        class="book-stay-wrapper"
      >
        <div class="container px-0">
          <BookingEngineDateSelector
            :formatted="dateFormatted"
            :checkIn="date.checkIn"
            :checkOut="date.checkOut"
            :disabled="fetchingAvailability"
            @search="searchCultswitchAvailability"
          />
          <Spinner small v-if="fetchingAvailability" />
          <CultswitchList
            v-else
            :availabilities="availabilities"
            :fetched="availabilitiesFetched"
            @cartUpdated="updateCart"
            :promoCode="promoCode"
            :stayMode="stayMode"
          />
        </div>
      </section>

      <section
        v-if="property.distributorType === 'B'"
        class="book-stay-wrapper"
      >
        <div class="container px-0">
          <BookingEngineDateSelector
            promo-disabled
            :formatted="dateFormatted"
            :checkIn="date.checkIn"
            :checkOut="date.checkOut"
            :disabled="fetchingAvailability"
            @search="searchEhotelAvailability"
          />
          <Spinner small v-if="fetchingAvailability" />
          <EhotelList
            v-else
            :availabilities="availabilities"
            :fetched="availabilitiesFetched"
            @bookNow="bookEhotelProduct"
          />
        </div>
      </section>

      <BookingRequestForm
        v-if="property.distributorType === 'C'"
        :property="property"
        :propertyInfo="propertyInfo"
      />

      <div class="container-fluid plr-80 my-5" v-if="propertyData.facilities.length">
        <div class="font-bold mb-2"><strong><h4>{{ $t('property.facilitiesAndExtras') }}</h4></strong></div>
        <div class="row">
          <div
            class="col-md-3 pb-2"
            v-for="facility in propertyData.facilities"
            :key="facility.code"
          >
            <img src="/images/check.svg">
            <span class="ml-2">{{ facility.serviceName }}</span>
          </div>
        </div>
      </div>

      <Footer ref="footerRef"></Footer>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Spinner from "./Widgets/Spinner.vue";
import StarRating from "./Widgets/StarRating.vue";
import EhotelList from "./Property/EhotelList.vue";
import CultswitchList from "./Property/CultswitchList.vue";
import BookingCheckout from "./Widgets/BookingCheckout.vue";
import BookingRequestForm from "./Property/BookingRequestForm.vue";
import BookingEngineDateSelector from "./Widgets/BookingEngineDateSelector.vue";
import moment from "moment";

export default {
  name: "Property",
  components: {
    Spinner,
    EhotelList,
    StarRating,
    CultswitchList,
    BookingCheckout,
    BookingRequestForm,
    BookingEngineDateSelector,
  },

  data() {
    return {
      cart: [],
      stayMode: false,
      promoCode: "",
      date: {
        checkIn: null,
        checkOut: null,
      },
      dateFormatted: false,
      availabilities: [],
      viewAllImages: false,
      availabilitiesFetched: false,
    };
  },
  computed: {
    ...mapState("property", [
      "propertyData",
      "fetching",
      "fetchingAvailability",
    ]),

    property() {
      return this.propertyData.property;
    },
    propertyInfo() {
      return this.propertyData.propertyInfo;
    },
    ratingValue() {
      const value = this.propertyData.googleReviews?.googleRating || 0;
      return parseFloat(value.toFixed(1));
    },
    description() {
      const description = this.property.descriptions.find(
        (desc) => desc.descriptionType.code === "hotel-long"
      );
      return description?.text;
    },
    propertyImages() {
      let imageMedia = this.propertyData.media
        .filter((m) => m.mediaType.code === "img")
        .map((m) => ({
          ...m,
          order: m.sortOrder || Number.MAX_VALUE,
        }))
        .sort((a, b) => a.order - b.order);

      const mainImageIndex = imageMedia.findIndex((m) => m.isMain);
      if (mainImageIndex > -1) {
        const [mainImage] = imageMedia.splice(mainImageIndex, 1);
        imageMedia.unshift(mainImage);
      }

      return imageMedia;
    },
  },
  methods: {
    ...mapActions("property", [
      "getPropertyById",
      "findEhotelAvailability",
      "findCultswitchAvailability",
    ]),
    async searchEhotelAvailability({ checkIn, checkOut }, setDate = true) {
      if (setDate) {
        this.date.checkIn = checkIn;
        this.date.checkOut = checkOut;
      }
      const { data } = await this.findEhotelAvailability({
        check_in: checkIn,
        check_out: checkOut,
        property_id: this.property.id,
      });
      this.availabilitiesFetched = true;
      this.availabilities = data.data;
    },
    async searchCultswitchAvailability(
      { checkIn, checkOut, promoCode },
      setDate = true
    ) {
      if (setDate) {
        this.date.checkIn = checkIn;
        this.date.checkOut = checkOut;
      }
      this.stayMode = moment(checkOut).diff(moment(checkIn), "days", true) > 1;
      this.promoCode = promoCode;
      try {
        const { data } = await this.findCultswitchAvailability({
          check_in: checkIn,
          check_out: checkOut,
          property_id: this.property.id,
          promo_code: promoCode,
        });
        this.availabilitiesFetched = true;
        this.availabilities = data?.data?.rooms || [];
      } catch (error) {
        this.$toastr.e(error.message);
      }
    },
    updateCart(cartItems) {
      this.cart = cartItems;
    },
    bookEhotelProduct(cartItem) {
      this.cart = [cartItem];
      this.proceedToReservation();
    },
    proceedToReservation() {
      sessionStorage.setItem(
        `checkoutData-${this.property.id}`,
        JSON.stringify({
          date: this.date,
          cart: this.cart,
          extras: [],
          promoCode: this.promoCode,
          distributorType: this.property.distributorType,
        })
      );
      this.$router.push(`/listing/${this.property.id}/reservation`);
    },
  },
  async created() {
    const { id } = this.$route.params;
    let { checkIn, checkOut, findAvailability } = this.$route.query;
    if (checkIn && checkOut) {
      checkIn = moment(checkIn, "YYYY-MM-DD").format("DD-MM-YYYY");
      checkOut = moment(checkOut, "YYYY-MM-DD").format("DD-MM-YYYY");
    } else {
      checkIn = moment(new Date()).format("DD-MM-YYYY");
      checkOut = moment(new Date()).add(1, "day").format("DD-MM-YYYY");
    }

    if (!id) {
      this.$router.push("/listing");
    }

    try {
      await this.getPropertyById(id);
    } catch (error) {
      this.$toastr.e(error.message);
    }

    this.date.checkIn = checkIn;
    this.date.checkOut = checkOut;
    if (findAvailability) {
      checkIn = moment(checkIn, "DD-MM-YYYY").format("YYYY-MM-DD");
      checkOut = moment(checkOut, "DD-MM-YYYY").format("YYYY-MM-DD");
      const payload = { checkIn, checkOut };
      this.date = payload;
      this.dateFormatted = true;
      if (this.property.distributorType === "A") {
        await this.searchCultswitchAvailability(payload, false);
      } else if (this.property.distributorType === "B") {
        await this.searchEhotelAvailability(payload, false);
      }
    }
  },
};
</script>
