<template>
  <div>
    <div v-if="availabilities.length" class="my-5">
      <div class="products-list my-3">
        <div
          v-for="availability in availabilities"
          :key="availability.id"
          class="mx-0 products-list-item"
        >
          <div class="row px-3 py-md-4 py-3">
            <div class="col-md-5">
              <div class="position-relative">
                <b-carousel
                  id="carousel-fade"
                  :interval="3000"
                  style="text-shadow: 0px 0px 2px #000"
                  fade
                  indicators
                  img-width="1024"
                  img-height="480"
                  ><div>
                    <template v-if="cultswitchRoomImages(availability).length">
                      <div
                        v-for="item of cultswitchRoomImages(availability)"
                        :key="item.id"
                      >
                        <b-carousel-slide
                          :img-src="item.url"
                        ></b-carousel-slide>
                      </div>
                    </template>
                    <template v-else>
                      <b-carousel-slide
                        v-for="i of 1"
                        :key="i"
                        img-src="/images/property-default-image.jpg"
                      ></b-carousel-slide>
                    </template>
                  </div>
                </b-carousel>
              </div>
            </div>
            <div class="col-md-7">
              <h3 class="mb-3 text-main font-weight-bold mt-md-0 mt-3">
                {{ availability.name }}
              </h3>
              <div v-html="availability.description"></div>
            </div>
          </div>
          <div
            v-for="product in availability.products"
            :key="product.id"
            class="selectors p-3"
          >
            <div
              v-for="(guest, index) in productGuests(product)"
              :key="`${product.id}-${index}`"
            >
              <div class="row mb-2">
                <div v-if="index === 0" class="col-md-5 col-9">
                  <div
                    class="small"
                    :class="`text-${
                      product.mealsIncluded ? 'success' : 'danger'
                    }`"
                  >
                    <template v-if="product.mealsIncluded">
                      <img
                        src="../../assets/icons/meal-success.svg"
                        class="mr-1"
                        alt="meal"
                      />
                      <span>
                        {{ product.mealsIncluded }}
                        {{ $t("property.included") }}
                      </span>
                    </template>
                    <template v-else>
                      <img
                        src="../../assets/icons/meal.svg"
                        class="mr-1"
                        alt="meal"
                      />
                      <span>{{ $t("property.noMealIncluded") }}</span>
                    </template>
                  </div>
                  <div class="mt-2">
                    {{ product.productDescription.name }}
                  </div>
                  <div class="small text-main mt-2">
                    <span
                      role="button"
                      v-b-popover.bottom.hover="
                        product.productDescription.description
                      "
                      >{{ $t("property.rateDetails") }}</span
                    >
                    |
                    <span role="button" v-b-popover.bottom.hover.html="''">{{
                      $t("property.cancellationPolicy")
                    }}</span>
                  </div>
                </div>
                <div v-if="index === 0" class="d-md-none d-block col-3">
                  <div class="text-14 text-right">
                    {{ $t("property.roomOrRooms") }}
                  </div>
                  <Multiselect
                    placeholder=""
                    style="width: 70px"
                    class="mt-3"
                    :options="cultswitchRoomsOption(product)"
                    :showLabels="false"
                    :searchable="false"
                    :allow-empty="false"
                    @select="cultswitchCartChanged($event, product)"
                    :value="productCart(product.id)?.quantity || 0"
                  />
                </div>
                <div
                  class="col-md-3 col-6 mt-md-0 mt-2"
                  :class="{ 'offset-md-5': index > 0 }"
                >
                  <div v-if="index === 0" class="text-14">
                    {{ $t("property.persons") }}
                  </div>
                  <div class="d-flex align-items-start mt-md-3 mt-1">
                    <div>
                      <Multiselect
                        placeholder=""
                        style="width: 100px"
                        class="mr-3"
                        :options="cultswitchPersonsOption(product)"
                        :showLabels="false"
                        :searchable="false"
                        :allow-empty="false"
                        @select="clearVariationSelection(product)"
                        v-model="guest.guestCount"
                      >
                        <template slot="singleLabel" slot-scope="props">
                          <img
                            v-for="i in props.option.value"
                            :key="i"
                            src="../../assets/icons/person.svg"
                            class="mr-1"
                            alt="person"
                          />
                        </template>
                        <template slot="option" slot-scope="props">
                          <img
                            v-for="i in props.option.value"
                            :key="i"
                            src="../../assets/icons/person.svg"
                            class="mr-1"
                            alt="person"
                          />
                        </template>
                      </Multiselect>
                      <div class="mt-1 text-14">
                        {{
                          $t("property.maxPersons", {
                            count: product.productPriceVariation.maxGuest,
                          })
                        }}
                      </div>
                    </div>
                    <div class="text-14">{{ $t("property.adults") }}</div>
                  </div>
                </div>
                <div
                  class="col-md-4 col-6 mt-md-0 mt-2 text-md-left text-right"
                >
                  <div
                    class="d-md-flex d-block align-items-start justify-content-between"
                  >
                    <div>
                      <div v-if="index === 0" class="text-14">
                        {{
                          $t(
                            `property.pricePer${
                              stayMode ? "Stay" : "RoomPerNight"
                            }`
                          )
                        }}
                      </div>
                      <div class="mt-md-2 mt-1">
                        <div
                          class="strike discounted-amount"
                          v-if="
                            cultswitchProductPrice(
                              product,
                              guest.guestCount,
                              'discountAmount'
                            ) > 0
                          "
                        >
                          {{ product.currencyCode }}
                          {{
                            cultswitchProductPrice(
                              product,
                              guest.guestCount,
                              "actualAmount"
                            )
                          }}
                        </div>
                        <h3 class="text-main font-weight-bold actual-amount">
                          {{ product.currencyCode }}
                          {{
                            cultswitchProductPrice(product, guest.guestCount)
                          }}
                        </h3>
                        <div
                          class="discounted-amount"
                          v-if="
                            cultswitchProductPrice(
                              product,
                              guest.guestCount,
                              'discountAmount'
                            ) > 0
                          "
                        >
                          {{ $t("property.youSave") }}:
                          <span class="text-main">
                            {{ product.currencyCode }}
                            {{
                              cultswitchProductPrice(
                                product,
                                guest.guestCount,
                                "discountAmount"
                              )
                            }}
                            ({{
                              cultswitchProductPrice(
                                product,
                                guest.guestCount,
                                "discountPercent",
                                0
                              )
                            }}%)
                          </span>
                        </div>
                      </div>
                    </div>
                    <div v-if="index === 0" class="d-none d-md-block">
                      <div>{{ $t("property.roomOrRooms") }}</div>
                      <Multiselect
                        placeholder=""
                        style="width: 70px"
                        class="mt-3"
                        :options="cultswitchRoomsOption(product)"
                        :showLabels="false"
                        :searchable="false"
                        :allow-empty="false"
                        @select="cultswitchCartChanged($event, product)"
                        :value="productCart(product.id)?.quantity || 0"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-for="(variation, idx) in guest.variations"
                :key="`${variation.minAge}-${index}-${idx}`"
              >
                <div class="row">
                  <div class="col-md-3 offset-md-5 col-6">
                    <div class="d-flex align-items-start mt-3">
                      <div>
                        <Multiselect
                          placeholder=""
                          style="width: 100px"
                          class="mr-3"
                          :options="
                            variationOption(
                              product,
                              guest.guestCount,
                              guest.variations
                            )
                          "
                          :showLabels="false"
                          :searchable="false"
                          :allow-empty="false"
                          @select="
                            variationSelected($event, product, index, idx)
                          "
                          :value="variation.quantity"
                        />
                      </div>
                      <div
                        class="mt-md-2 mt-0 text-14"
                        :class="{ 'custom-opacity-45': variation.quantity <= 0 }"
                      >
                        {{ $t("property.children") }} ({{ variation.minAge }} -
                        {{ variation.maxAge }})
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 col-6 text-md-left text-right">
                    <div
                      class="mt-3"
                      :class="{ 'custom-opacity-45': variation.quantity <= 0 }"
                    >
                      <h3 class="text-main font-weight-bold actual-amount">
                        {{ product.currencyCode }}
                        {{ variation.totalAmount }}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="index < productGuests(product).length - 1"
                class="offset-md-5 col-md-6 mt-4"
              >
                <hr />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="fetched && !availabilities.length" class="p-5 mt-5 text-center">
      <h5>
        {{ $t("property.noDeals") }}
        <span v-if="promoCode">{{ $t("property.andPromo") }}</span>
      </h5>
    </div>
    <div v-else-if="!fetched" class="py-5 mt-5 text-center text-md">
      <h5>{{ $t("property.selectStay") }}</h5>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
export default {
  name: "CultswitchList",
  props: {
    availabilities: {
      type: Array,
      required: true,
    },
    stayMode: {
      type: Boolean,
      required: true,
    },
    fetched: {
      type: Boolean,
      default: false,
    },
    promoCode: {
      type: String,
      default: "",
    },
    guests: {
      type: Number,
      default: 2,
    },
    rooms: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      cart: [],
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    cultswitchRoomImages(availability) {
      const images = {};
      availability.media.forEach((image) => {
        images[image.id] = image;
      });

      return Object.values(images).sort((a, b) => a.sortOrder - b.sortOrder);
    },
    productCart(productId) {
      return this.cart.find(({ id }) => id === productId);
    },
    cultswitchPersonsOption(product) {
      const { minGuest, maxGuest } = product.productPriceVariation;
      return Array.from(
        { length: maxGuest - minGuest + 1 },
        (_, i) => minGuest + i
      );
    },
    variationOption(product, guests, variations) {
      let totalSelected = guests;
      variations.forEach(({ quantity }) => {
        totalSelected += quantity;
      });
      const { minGuest, maxGuest } = product.productPriceVariation;
      const available = maxGuest - totalSelected;
      if (available < minGuest) {
        return [0];
      }

      return Array.from({ length: available + 1 }, (_, index) => index);
    },
    productGuests(product) {
      const item = this.productCart(product.id);
      if (!item) {
        const data = {
          id: product.id,
          product,
          quantity: 0,
          guests: [
            {
              guestCount: product.productPriceVariation.stdGuest || 1,
              price: this.cultswitchProductPrice(product),
              variations: this.priceVariations(product),
            },
          ],
          currencyCode: product.currencyCode,
        };
        this.cart.push(data);
      }
      return item?.guests;
    },
    cultswitchRoomsOption(product) {
      return Array.from({ length: product.numberOfUnits + 1 }, (_, i) => i);
    },
    cultswitchProductPrice(
      product,
      guests = 1,
      key = "totalAmount",
      precision = 2
    ) {
      let prices = product.roomRates;
      if (prices[guests.toString()]) {
        return prices[guests.toString()][key].toFixed(precision);
      }
      prices = Object.values(prices);
      return (prices.length ? prices[0][key] : 0).toFixed(precision);
    },
    priceVariations(product, guests = 1) {
      const variations = product.productPriceVariation.priceVariation.filter(
        ({ maxAge }) => maxAge > 0
      );
      return variations
        .map((variation) => {
          variation.totalAmount = variation.value;
          variation.quantity = 0;
          if (variation.isPercent) {
            variation.totalAmount =
              (variation.value / 100) *
              this.cultswitchProductPrice(product, guests, "totalAmount", 0);
          }
          variation.totalAmount =
            (variation.totalAmount + variation.extraTotal) *
            variation.multiplier;
          return variation;
        })
        .sort((a, b) => a.minAge - b.minAge);
    },
    clearVariationSelection(product) {
      const idx = this.cart.findIndex(({ id }) => id === product.id);
      if (idx !== -1) {
        this.cart[idx].guests.forEach((guest) => {
          guest.variations = this.priceVariations(product, guest.guestCount);
        });
      }
    },
    variationSelected(value, product, guestIdx, varIdx) {
      const idx = this.cart.findIndex(({ id }) => id === product.id);
      if (idx !== -1) {
        const updatedCartItem = { ...this.cart[idx] };
        updatedCartItem.guests[guestIdx].variations[varIdx].quantity = value;
        this.cart.splice(idx, 1, updatedCartItem);
      }
    },
    cultswitchCartChanged(value, product) {
      const idx = this.cart.findIndex(({ id }) => id === product.id);
      if (idx === -1) {
        if (value > 0) {
          const data = {
            id: product.id,
            product,
            quantity: value,
            guests: [],
            currencyCode: product.currencyCode,
          };
          for (let i = 0; i < value; i++) {
            data.guests.push({
              guestCount: product.productPriceVariation.stdGuest || 1,
              price: this.cultswitchProductPrice(product),
              variations: this.priceVariations(product),
            });
          }
          this.cart.push(data);
        }
      } else {
        if (value === 0) {
          this.cart.splice(idx, 1);
        } else {
          this.cart[idx].guests = [];
          this.cart[idx].quantity = value;
          for (let i = 0; i < value; i++) {
            const guestCount = product.productPriceVariation.stdGuest || 1;
            this.cart[idx].guests.push({
              guestCount,
              price: this.cultswitchProductPrice(product),
              variations: this.priceVariations(product, guestCount),
            });
          }
        }
      }
    },
  },
  watch: {
    availabilities: {
      handler(availabilities) {
        availabilities.forEach((availabily) => {
          availabily.products.forEach((product) => {
            this.clearVariationSelection(product)
          })
        })
      },
      deep: true,
      immediate: true,
    },
    cart: {
      handler(updatedCart) {
        updatedCart.forEach((item, idx) => {
          item.guests.forEach((guest, idxx) => {
            this.cart[idx].guests[idxx].price = this.cultswitchProductPrice(
              item.product,
              guest.guestCount
            );
          });
        });
        const localCart = JSON.parse(JSON.stringify(this.cart));
        this.$emit(
          "cartUpdated",
          localCart.filter((item) => item.quantity > 0)
        );
      },
      deep: true,
    },
  },
};
</script>

<style>
.strike {
  text-decoration: line-through;
}
.text-14 {
  font-size: 14px;
}
.discounted-amount {
  font-size: 14px;
}
.custom-opacity-45 {
  opacity: 40% !important;
}
.actual-amount {
  font-size: 28px;
}

@media screen and (max-width: 600px) {
  .discounted-amount {
    font-size: 12px;
  }
  .actual-amount {
    font-size: 20px;
    margin-bottom: 0;
  }
  .text-14 {
    font-size: 12px;
  }
}
</style>
